<template>
  <div>
      <vote-link :layout="layout"></vote-link>
  </div>
</template>

<script>
import VoteLink from "@/components/vote/VoteLink";
import voteUtil from "@/components/vote/voteUtil";

export default {
  name: "VoteComponent",
  mixins: [voteUtil],
  components: {
    VoteLink,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    voteType: '',
  },
  data() {
    return{
      layout: '',
    }
  },
  beforeRouterEnter() {},
  created() {
    this.initConfig();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    initConfig(){
      let voteConfig = this.returnVoteConfig(this.voteType);
      this.layout = voteConfig.layout;
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>