<template>
  <div>
    <judge-link :listLayout="listLayout"></judge-link>
  </div>
</template>

<script>
import JudgeLink from "@/components/judge/JudgeLink";
import judgeUtil from "@/components/judge/judgeUtil";

export default {
  name: "JudgeListComponent",
  mixins: [judgeUtil],
  components: {
    JudgeLink,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    judgeType: '',
  },
  data() {
    return{
      listLayout: '',
    }
  },
  beforeRouterEnter() {},
  created() {
    this.initConfig();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    initConfig(){
      let judgeConfig = this.returnJudgeConfig(this.judgeType);
      this.listLayout = judgeConfig.listLayout;
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>