const voteConfig = {
    vote: {
        layout: 'VoteDefaultLayout',
    },

}
export default {
    methods: {
        returnVoteConfig(type) {
            return voteConfig[type];
        }
    },
}
