<template>
  <div class="competition_detail_wrap">
    <div class="" v-if="true">
      <vote-component :vote-type="'vote'"></vote-component>
    </div>
    <div class="" v-else-if="false">
      <judge-list-component :judge-type="'judgeBefore'"></judge-list-component>
    </div>
    <div class="" v-else-if="false">
      <judge-list-component :judge-type="'judgeAfter'"></judge-list-component>
    </div>
    <div class="" v-else>
      <judge-list-component :judge-type="'organizer'"></judge-list-component>
    </div>
  </div>
</template>

<script>
import VoteComponent from "@/components/vote/VoteComponent";
import JudgeListComponent from "@/components/judge/JudgeListComponent";

export default {
  name: "CompetitionDetailEntryLayout",
  mixins: [],
  components: {
      JudgeListComponent,
    VoteComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>