<template>
  <component :is="component" v-if="component"></component>
</template>

<script>
export default {
  name: "JudgeLink",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    listLayout: '',
  },
  data() {
    return{
      component: null,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
    this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import('@/template/judge/list/JudgeListDefaultLayout')
        })
  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
    loader() {
      if (!this.listLayout) {
        return null
      }
      return () => import(`@/template/judge/list/${this.listLayout}`)
    },
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>